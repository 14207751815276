<template>
  <div style="text-align:center">
    <el-row justify="center" align="middle" style="height:100vh">
      <el-col :span="6">
        <el-container class="a-container ar-card d-flex flex-column ph5 pb4" style="width:60vh; box-shadow: none;">
          <div>
            <el-icon size="7em">
              <Logo />
            </el-icon>
          </div>
          <el-form label-position="top">
            <div v-if="error" class="mb3">
              <el-tag class="ml-2" type="danger">
                {{ error }}
              </el-tag>
            </div>
            <el-form-item>
              <el-input v-model="form.email" placeholder="Email" type="email" size="large" />
            </el-form-item>
            <el-form-item>
              <el-input v-model="form.password" placeholder="Пароль" type="password" show-password size="large" />
            </el-form-item>
            <el-form-item>
              <div style="width:100%;display:flex; flex-direction:row; justify-content:center">
                <el-button style="width:10em" type="primary" size="large" :loading="loading" @click="login">
                  Вход
                </el-button>
                <!-- <el-button link size="large" @click="redirectToRegistration">
                  Регистрация
                </el-button> -->
              </div>
            </el-form-item>
          </el-form>
        </el-container>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { loginAndRedirect } from '@/api/helpers';
import {
  Logo,
} from '@/assets/icons/index.js';

export default {
  components: {

    Logo,
  },
  name: 'Auth',
  data: () => ({
    error: null,
    loading: false,
    form: {
      email: '',
      password: '',
    },
  }),

  // created() {
  //   window.location.reload();
  // },
  methods: {
    redirectToRegistration() {
      this.$router.push('/registration');
    },
    redirectToMain() {
      this.$router.push('/release-list');
    },
    async login() {
      this.loading = true;
      await this.$axios.post('/user/authorize', this.form).then((res) => {
        const { data } = res;
        loginAndRedirect(data);
      }).catch((e) => {
        if (e.response.status === 403) {
          this.error = 'Неверный логин или пароль.';
        } else {
          this.error = e.response.data[0];
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.row-bg {
  background: $primaryBlue;
}

</style>
